// @flow
import enGB from './en-gb.json'
import enUS from './en-us.json'
import esMX from './es-mx.json'
import ptBR from './pt-br.json'
import deAT from './de-at.json'

const metaDefaults = {
  'en-GB': enGB.meta,
  'en-US': enUS.meta,
  'es-MX': esMX.meta,
  'pt-BR': ptBR.meta,
  'de-AT': deAT.meta,
}

export default metaDefaults

// @flow

import React from 'react'
import classNames from 'classnames'
import styles from './SyllabusTopicNav.module.scss'

type Props = {
  className?: string,
  topics: Object,
  handleClick: string => any,
}

const SyllabusTopicNav = (props: Props) => {
  const { className, topics, handleClick } = props

  return (
    <div className={classNames(styles.container, className)}>
      {topics &&
        topics.map(topic => {
          return (
            <div key={topic.topicName}>
              <h4>{topic.topicName}</h4>
              {topic.subTopic &&
                topic.subTopic.map(subTopic => (
                  <div key={subTopic.subTopicName} className={styles.linkList}>
                    <li
                      onClick={() => handleClick(topic, subTopic)}
                      className={styles.linkListItem}
                    >
                      {subTopic.subTopicName}
                    </li>
                  </div>
                ))}
            </div>
          )
        })}
    </div>
  )
}

export default SyllabusTopicNav

// @flow

import React from 'react'

import { ButtonLink } from 'src/common/components/Button'
import { useTranslateString } from 'src/intl'

import styles from './SyllabusPointHeader.module.scss'
import ResourceList from './ResourceList'
import Divider from './Divider'

type Props = {
  syllabusMapTitle: string,
  syllabusPointName: string,
  resources: Object,
  subTopicName: string,
  topicName: string,
}

const SyllabusPointHeader = ({
  syllabusMapTitle,
  syllabusPointName,
  resources,
  topicName,
  subTopicName,
}: Props) => {
  const buttonText = useTranslateString('syllabus.body.buttonText')

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h4>{syllabusPointName}</h4>
        <ButtonLink
          color="blue"
          external={true}
          text={buttonText}
          linkExternal={`https://docs.google.com/forms/d/e/1FAIpQLScqQ9Uf_UogoFs4El02tCcWyAqZBPsJFy-hPcYYpWPO6PhxLw/viewform?entry.646629836=${syllabusMapTitle}&entry.1959189329=${topicName}&entry.227349554=${subTopicName}&entry.1424183974=${syllabusPointName}`}
          className={styles.submitResourceButton}
          openInNewTab
        />
      </div>
      <Divider />
      {resources &&
        resources.map(resource => (
          <div key={resource.resourceName} className={styles.linkList}>
            <li>
              <ResourceList
                resource={resource}
                syllabusMapTitle={syllabusMapTitle}
                syllabusPointName={syllabusPointName}
                topicName={topicName}
                subTopicName={subTopicName}
              />
            </li>
          </div>
        ))}
    </div>
  )
}
export default SyllabusPointHeader

// @flow
import * as React from 'react'

import styles from './SyllabusPointContainer.module.scss'
import MobileMenuIcon from './MobileMenuIcon'
import SyllabusPointHeader from './SyllabusPointHeader'
import Divider from './Divider'

type Props = {
  syllabusMapTitle: string,
  topic: Object,
  subTopic: Object,
  isMobileMenuOpen: boolean,
  handleMobileMenuOpenOrClose: () => void,
  renderMobileTopicNav: () => React.Node,
}

const SyllabusPointContainer = ({
  syllabusMapTitle,
  topic,
  subTopic,
  handleMobileMenuOpenOrClose,
  isMobileMenuOpen,
  renderMobileTopicNav,
}: Props) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.mobileIconContainer}
        onClick={handleMobileMenuOpenOrClose}
      >
        <MobileMenuIcon isActive={isMobileMenuOpen} />
        <span className={styles.mobileIconText}>
          {isMobileMenuOpen ? 'Close' : 'Select topic'}
        </span>
      </div>

      {isMobileMenuOpen ? (
        renderMobileTopicNav()
      ) : (
        <>
          <h3>{subTopic.subTopicName}</h3>
          <Divider />
          {subTopic.syllabusPoints.map(syllabusPoint => (
            <SyllabusPointHeader
              syllabusMapTitle={syllabusMapTitle}
              syllabusPointName={syllabusPoint.syllabusPointName}
              resources={syllabusPoint.resources}
              key={syllabusPoint.syllabusPointName}
              topicName={topic.topicName}
              subTopicName={subTopic.subTopicName}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default SyllabusPointContainer

// @flow
import * as React from 'react'
import classNames from 'classnames'

import SyllabusPointContainer from './SyllabusPointContainer'
import styles from './SyllabusSubTopicCard.module.scss'

type Props = {
  syllabusMapTitle: string,
  topic: Object,
  subTopic: Object,
  renderMobileTopicNav: () => React.Node,
  handleMobileMenuOpenOrClose: () => void,
  className?: string,
  isMobileMenuOpen: boolean,
}

const SyllabusSubTopicCard = ({
  className,
  topic,
  subTopic,
  isMobileMenuOpen,
  handleMobileMenuOpenOrClose,
  renderMobileTopicNav,
  syllabusMapTitle,
}: Props) => {
  return (
    <div className={classNames(styles.container, className)}>
      <SyllabusPointContainer
        syllabusMapTitle={syllabusMapTitle}
        topic={topic}
        subTopic={subTopic}
        renderMobileTopicNav={renderMobileTopicNav}
        handleMobileMenuOpenOrClose={handleMobileMenuOpenOrClose}
        isMobileMenuOpen={isMobileMenuOpen}
      />
    </div>
  )
}

export default SyllabusSubTopicCard

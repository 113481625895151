// @flow
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import pushEventToDataLayer from '../../analytics/pushEventToDataLayer'
import metaDefaults from '../../../content/metaDefaults'
import { getLocalesWithAlternatePage } from '../../links/utils'
import { externalStaticSiteLink } from '../../links'

type PageContext = {
  locale: string,
  pagePath: ?string,
}

type Props = {|
  pageContext: PageContext,
  pageUrlKey?: string,
  pageTitle: ?string,
  metaDescription: ?string,
  ogImage: ?string,
  ogImageAlt: ?string,
  twitterSite: ?string,
  twitterCreator: ?string,
|}

const GOOGLE_SITE_VERIFICATION_CODE =
  'oxdrXO3MUz7qinzGceVRPVIte-o3kS5NENEn_Le7B1Y';

const UK_FACEBOOK_DOMAIN_VERIFICATION_CODE = 'cj5hi7w6hthpl2otjbnm9dz1fsoxci';

const DACH_FACEBOOK_DOMAIN_VERIFICATION_CODE = '21j6cff7xampn30e9br4h1dt8m96b7';


export default function HeadTags(props: Props) {
  const {
    pageContext: { locale, pagePath },
    pageTitle,
    metaDescription,
    ogImage,
    ogImageAlt,
    twitterSite,
    twitterCreator,
  } = props

  const {
    site: {
      siteMetadata: { branch },
    },
  } = useStaticQuery(
    graphql`
      query SiteQuery {
        site {
          siteMetadata {
            branch
          }
        }
      }
    `
  )

  useEffect(() => {
    // push the current branch to GTM data layer for A-B testing
    pushEventToDataLayer({ branch })
  }, [branch])

  const defaults = metaDefaults[locale]

  const localesWithAlternatePage = getLocalesWithAlternatePage(pagePath)

  return (
    <Helmet>
      <html lang={locale} />
      <title>{pageTitle || defaults.pageTitle}</title>
      <meta
        name="description"
        content={metaDescription || defaults.metaDescription}
      />
      <meta
        name="google-site-verification"
        content={GOOGLE_SITE_VERIFICATION_CODE}
      />
      <meta
        name="facebook-domain-verification"
        content={UK_FACEBOOK_DOMAIN_VERIFICATION_CODE}
      />
      <meta
        name="facebook-domain-verification"
        content={DACH_FACEBOOK_DOMAIN_VERIFICATION_CODE}
      />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle || defaults.pageTitle} />
      <meta
        property="og:description"
        content={metaDescription || defaults.metaDescription}
      />
      <meta property="og:image" content={ogImage || defaults.ogImage} />
      <meta name="twitter:title" content={pageTitle || defaults.pageTitle} />
      <meta
        name="twitter:description"
        content={metaDescription || defaults.metaDescription}
      />
      <meta name="twitter:site" content={twitterSite || defaults.twitterSite} />
      <meta
        name="twitter:creator"
        content={twitterCreator || defaults.twitterCreator}
      />
      <meta name="twitter:image" content={ogImage || defaults.ogImage} />
      {ogImageAlt && <meta name="twitter:image:alt" content={ogImageAlt} />}
      {localesWithAlternatePage.map(locale => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={externalStaticSiteLink(pagePath)(locale)}
        />
      ))}
    </Helmet>
  )
}

// @flow
import React from 'react'
import classNames from 'classnames'
import styles from './MobileMenuIcon.module.scss'

type PropTypes = {
  isActive: boolean,
  toggleState?: () => void,
}

const MobileMenuIcon = ({ toggleState, isActive }: PropTypes) => {
  return (
    <button
      className={classNames(styles.hamburger, styles.hamburgerSpin, {
        [styles.isActive]: isActive,
      })}
      onClick={toggleState}
      type="button"
    >
      <span className={styles['hamburgerBox']}>
        <span className={styles['hamburgerInner']} />
      </span>
    </button>
  )
}

export default MobileMenuIcon

// @flow

import React from 'react'

import { useTranslateString } from 'src/intl'

import senecaLogo from 'src/common/images/resources/senecaLogo.svg'
import quizlet from 'src/common/images/resources/quizlet.png'
import teacher from 'src/common/images/resources/teacher.svg'
import exam from 'src/common/images/resources/exam.svg'
import knowledge from 'src/common/images/resources/list.svg'
import video from 'src/common/images/resources/video.svg'
import activity from 'src/common/images/resources/work.svg'
import kahoot from 'src/common/images/resources/kahoot.png'
import powerpoint from 'src/common/images/resources/powerpoint.svg'
import caseStudy from 'src/common/images/resources/case-study.svg'
import link from 'src/common/images/resources/link.svg'

import styles from './ResourceList.module.scss'

type Props = {|
  resource: Object,
  syllabusMapTitle: string,
  syllabusPointName: string,
  topicName: string,
  subTopicName: string,
|}

function ResourceList({
  resource,
  syllabusMapTitle,
  syllabusPointName,
  topicName,
  subTopicName,
}: Props) {
  const { resourceURL, resourceName, resourceType } = resource

  const linkText = useTranslateString('syllabus.body.linkText')

  return (
    <div className={styles.container}>
      <a
        href={resourceURL}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.linkListItem}
      >
        <div className={styles.resourceIcon}>
          <img src={iconURL(resourceType)} className={styles.iconImg} />
        </div>
        <div className={styles.resourceName}>{resourceName}</div>
      </a>
      <a
        href={`https://docs.google.com/forms/d/e/1FAIpQLSeQClTi9tmRsF0xiaBfX73ZVV4EhOz5-hcZjEUm6e2UltO-AQ/viewform?entry.1987001680=${syllabusMapTitle}&entry.1552801369=${topicName}&entry.1003833527=${subTopicName}&entry.772369595=${syllabusPointName}&entry.640181803=${resourceName}&entry.233416134=${resourceURL}`}
        className={styles.link}
      >
        {linkText}
      </a>
    </div>
  )
}

function iconURL(resourceType) {
  switch (resourceType) {
    case 'SENECA':
      return senecaLogo
    case 'QUIZLET':
      return quizlet
    case 'TEACHER RESOURCE':
      return teacher
    case 'EXAM RESOURCE':
      return exam
    case 'KNOWLEDGE ORGANISER':
      return knowledge
    case 'VIDEO':
      return video
    case 'ACTIVITY':
      return activity
    case 'KAHOOT':
      return kahoot
    case 'PRESENTATION':
      return powerpoint
    case 'CASE STUDY':
      return caseStudy
    case 'LINK-OTHER':
      return link
    default:
      return link
  }
}

export default ResourceList

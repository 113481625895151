// @flow
import React from 'react'
import { graphql, Link } from 'gatsby'
import { injectIntl } from 'react-intl'

import { withIntl } from 'src/intl'

import {
  Layout,
  Navbar,
  BackgroundImage,
  Footer,
  PageRow,
  Heading,
} from 'src/common/components'
import { RESOURCES } from 'src/common/images'

import SyllabusSubTopicCard from 'src/components/SyllabusSubTopicCard/components/SyllabusSubTopicCard'
import SyllabusTopicNav from 'src/components/SyllabusTopicNav/components/SyllabusTopicNav'
import { ButtonLink } from 'src/common/components/Button'
import HeadTags from 'src/components/HeadTags'

import { linkToSyllabusPage } from 'src/links'

import styles from './syllabus-post.module.scss'

type PageContext = {
  locale: string,
}

type Props = {
  data: Object,
  intl: Object,
  pageContext: PageContext,
}

type State = {
  currentTopic: Object,
  currentSubTopic: Object,
  isMobileMenuOpen: boolean,
}

const MOBILE_SCREEN_AT = 900

class SyllabusPost extends React.Component<Props, State> {
  state = {
    currentTopic: undefined,
    currentSubTopic: undefined,
    isMobileMenuOpen: false,
  }

  componentDidMount() {
    const firstTopic = this.props.data.markdownRemark.frontmatter.topics[0]
    this.setState({
      currentTopic: firstTopic,
      currentSubTopic: firstTopic.subTopic[0],
    })

    // make mobile menu default open if on relevant screen
    if (window.innerWidth < MOBILE_SCREEN_AT) {
      this.setState({ isMobileMenuOpen: true })
    }

    window.addEventListener('resize', this.handleWindowResize.bind(this))
  }

  componentWillUnmount() {
    if (window !== typeof undefined) {
      window.removeEventListener('resize', this.handleWindowResize.bind(this))
    }
  }

  handleWindowResize() {
    if (window.innerWidth >= MOBILE_SCREEN_AT) {
      this.setState({ isMobileMenuOpen: false })
    }
  }

  handleClick = (topic, subTopic) => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }

    this.setState({
      currentTopic: topic,
      currentSubTopic: subTopic,
      isMobileMenuOpen: false,
    })
  }

  handleMobileMenuOpenOrClose() {
    this.setState(prevState => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }))
  }

  render() {
    const { data, intl, pageContext } = this.props
    const { currentTopic, currentSubTopic } = this.state
    const { markdownRemark: post } = data
    const {
      title,
      topics,
      locale,
      meta: { pageTitle, metaDescription, ogImage, ...otherMeta },
    } = post.frontmatter

    const { excerpt } = post

    const { footer } = data.footer.frontmatter

    const buttonText = intl.formatMessage({ id: 'syllabus.intro.buttonText' })
    const linkText = intl.formatMessage({ id: 'syllabus.intro.linkText' })

    return (
      <>
        <HeadTags
          pageContext={pageContext}
          pageTitle={pageTitle ? pageTitle : title}
          metaDescription={metaDescription ? metaDescription : excerpt}
          ogImage={ogImage || RESOURCES}
          {...otherMeta}
        />
        <Layout>
          {{
            navbar: <Navbar pagePath={pageContext.pagePath} />,
            background: <BackgroundImage />,
            footer: <Footer content={footer} />,
            body: (
              <>
                {!currentTopic ? null : (
                  <PageRow firstRow>
                    <Heading text={title} />
                    <div className={styles.buttonRow}>
                      <Link
                        to={linkToSyllabusPage(locale)}
                        className={styles.backButton}
                      >
                        <span className={styles.backText}>{linkText}</span>
                      </Link>
                      <ButtonLink
                        external={true}
                        linkExternal="https://docs.google.com/forms/d/e/1FAIpQLSf8oDFs1EGdGwaS3CGmQrB51JH-Y72OqYInVR80IbaMgrjXSA/viewform?usp=sf_link"
                        text={buttonText}
                        color="transparent"
                        openInNewTab
                      />
                    </div>
                    <div className={styles.rowContainer}>
                      <SyllabusTopicNav
                        className={styles.navBody}
                        topics={topics}
                        handleClick={this.handleClick}
                      />
                      <SyllabusSubTopicCard
                        className={styles.contentBody}
                        syllabusMapTitle={title}
                        topic={currentTopic}
                        subTopic={currentSubTopic}
                        renderMobileTopicNav={() => (
                          <SyllabusTopicNav
                            topics={topics}
                            handleClick={this.handleClick.bind(this)}
                          />
                        )}
                        handleMobileMenuOpenOrClose={this.handleMobileMenuOpenOrClose.bind(
                          this
                        )}
                        isMobileMenuOpen={this.state.isMobileMenuOpen}
                      />
                    </div>
                  </PageRow>
                )}
              </>
            ),
          }}
        </Layout>
      </>
    )
  }
}

export default withIntl(injectIntl(SyllabusPost))

export const pageQuery = graphql`
  query syllabusPostByID($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 150)
      frontmatter {
        locale
        title
        subject
        ageGroup
        examBoard
        topics {
          topicName
          subTopic {
            subTopicName
            syllabusPoints {
              syllabusPointName
              resources {
                resourceName
                resourceType
                resourceURL
              }
            }
          }
        }
        ...MetaFragment
      }
    }
    ...FooterFragment
  }
`
